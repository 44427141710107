import gql from 'graphql-tag'

export const BlockUpdateFragment = gql`
  fragment BlockUpdate on Block {
    name
    title
    roles
    type
    collectionId
    previewParams
    itemId
    content
    exportXLSX
    disableRefresh
    filterable
    allowsNoFilter
    filterByDefault
    orderBy
    orderByAsc
    filtersIds
    paginationStyle
    defaultLimit
  }
`

export const BlockCreateFragment = gql`
  fragment BlockCreate on Block {
    _id
    environmentId
  }
`

export const BlockFragment = gql`
  fragment Block on Block {
    ...BlockCreate
    ...BlockUpdate
  }

  ${BlockCreateFragment}
  ${BlockUpdateFragment}
`
